import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { HTMLAttributes, PropsWithChildren } from 'react'

export type PlainTableHeader = HTMLAttributes<HTMLTableSectionElement> & {
    lightColorHeader?: boolean
    defaultColor?: boolean
    isRounded?: boolean
}

const PlainTableHeader = ({
    children,
    className,
    lightColorHeader,
    defaultColor,
    isRounded,
    ...props
}: PropsWithChildren<PlainTableHeader>) => {
    return (
        <thead
            {...props}
            className={buildClassesWithDefault(
                { '!bg-gray-300': defaultColor, '!bg-gray-200': lightColorHeader, 'rounded-l-[10px]': isRounded },
                className
            )}
        >
            {children}
        </thead>
    )
}

export default PlainTableHeader
