import { buildClasses } from '../../../utils/StyleHelper'
import React, { CSSProperties, PropsWithChildren, ThHTMLAttributes } from 'react'

export type PlainTableHeaderRowCellPropsSticky = {
    enabled: boolean
    left: number
    zIndex?: number
}

export type PlainTableHeaderRowCellProps = ThHTMLAttributes<HTMLElement> & {
    sticky?: PlainTableHeaderRowCellPropsSticky
}

const PlainTableHeaderRowCell = ({
    children,
    scope,
    style,
    className,
    sticky: { enabled, left, zIndex } = { enabled: false, left: 0, zIndex: 1 },
    ...props
}: PropsWithChildren<PlainTableHeaderRowCellProps>) => {
    const stickyStyles: CSSProperties = enabled
        ? {
              position: 'sticky',
              left,
              zIndex
          }
        : {}

    const styles: CSSProperties = {
        ...style,
        ...stickyStyles
    }

    return (
        <th
            {...props}
            scope={scope || 'col'}
            style={styles}
            className={buildClasses(
                {
                    'sticky-col': enabled
                },
                className
            )}
        >
            {children}
        </th>
    )
}

export default PlainTableHeaderRowCell
