import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import GridColumn from '../grid/Column'
import React, { ForwardedRef, HTMLAttributes, PropsWithChildren, forwardRef } from 'react'
import Row from '../grid/Row'
import Skeleton from 'react-loading-skeleton'

export type PlainTableProps = HTMLAttributes<HTMLTableElement> & {
    containerClassName?: string
    tableParentClassName?: string
    loading?: boolean
    textNoWrap?: boolean
    textCenter?: boolean
    sticky?: boolean
    disableNativeScrollbar?: boolean
    maxHeight?: number
    minHeight?: number
    compact?: boolean
    hoverEffect?: boolean
    striped?: boolean
}

const PlainTable = (
    {
        children,
        containerClassName: containerClassNameProp,
        loading,
        textNoWrap,
        textCenter,
        className,
        sticky,
        disableNativeScrollbar,
        maxHeight,
        minHeight,
        compact,
        striped,
        hoverEffect = true,
        ...props
    }: PropsWithChildren<PlainTableProps>,
    ref: ForwardedRef<HTMLDivElement>
) => {
    const containerClassName = buildClassesWithDefault(
        {
            'plain-table': true,
            flex: true,
            'flex-col': true,
            'whitespace-nowrap': textNoWrap,
            'sticky-table': sticky,
            'table-center': textCenter,
            'overflow-x-auto': !disableNativeScrollbar,
            'hover-effect': hoverEffect,
            compact,
            striped
        },
        containerClassNameProp
    )

    const renderSkeletonLoading = () => {
        return (
            loading && (
                <Row className='w-full bg-white flex'>
                    <GridColumn cols={1}>
                        <Skeleton count={9} height={16} className='my-2' />
                    </GridColumn>
                    <GridColumn cols={3}>
                        <Skeleton count={9} height={16} className='my-2' />
                    </GridColumn>
                    <GridColumn cols={3}>
                        <Skeleton count={9} height={16} className='my-2' />
                    </GridColumn>
                    <GridColumn cols={3}>
                        <Skeleton count={9} height={16} className='my-2' />
                    </GridColumn>
                    <GridColumn cols={2}>
                        <Skeleton count={9} height={16} className='my-2' />
                    </GridColumn>
                </Row>
            )
        )
    }

    return (
        <div className={containerClassName} ref={ref} style={{ maxHeight, minHeight }}>
            {!loading && (
                <table {...props} className={className}>
                    {children}
                </table>
            )}
            {renderSkeletonLoading()}
        </div>
    )
}

export default forwardRef(PlainTable)
