import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { ForwardedRef, HTMLAttributes, PropsWithChildren, forwardRef } from 'react'

export type PlainTableBodyRowProps = HTMLAttributes<HTMLTableRowElement> & {
    darken?: boolean
    disabledHoverEffect?: boolean
    light?: boolean
    isRounded?: boolean
}

const PlainTableBodyRow = (
    {
        children,
        className,
        darken,
        disabledHoverEffect,
        light,
        isRounded = false,
        ...props
    }: PropsWithChildren<PlainTableBodyRowProps>,
    ref: ForwardedRef<HTMLTableRowElement>
) => {
    return (
        <tr
            {...props}
            ref={ref}
            className={buildClassesWithDefault(
                {
                    'darken-row': darken || false,
                    'light-row': light || false,
                    'disabled-hover-effect': disabledHoverEffect,
                    '[&>*:first-child]:rounded-l-lg': isRounded,
                    '[&>*:last-child]:rounded-r-lg': isRounded
                },
                className
            )}
        >
            {children}
        </tr>
    )
}

export default forwardRef(PlainTableBodyRow)
