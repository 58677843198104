import 'react-datepicker/dist/react-datepicker.css'
import { CalendarIcon } from '@heroicons/react/outline'
import { addYears, subYears } from 'date-fns'
import { buildDate } from '../../../utils/Helper'
import { useTranslation } from 'next-i18next'
import ExternalDatePicker, { ReactDatePickerProps as ExternalDatePickerProps } from 'react-datepicker'
import React, { ReactNode } from 'react'
import TextField, { TextFieldProps } from '../text-field/TextField'

const DEFAULT_MIN_DATE = subYears(
    buildDate(current => {
        return new Date(current.getFullYear(), 0, 1)
    }),
    120
)
const DEFAULT_MAX_DATE = addYears(
    buildDate(current => {
        return new Date(current.getFullYear(), 11, 31)
    }),
    10
)

export type DatePickerProps<
    CustomModifierNames extends string = never,
    WithRange extends boolean | undefined = undefined
> = Omit<ExternalDatePickerProps<CustomModifierNames, WithRange>, 'dateFormat'> & {
    textFieldProps?: Omit<TextFieldProps, 'label' | 'containerClassName'>
    dateFormat?: string
    headerDateFormat?: string
    label?: ReactNode
    inputClassName?: string
}

const DatePicker = <CustomModifierNames extends string = never, WithRange extends boolean | undefined = undefined>({
    children,
    label,
    textFieldProps,
    customInput,
    locale,
    dateFormat = 'MM/dd/yyyy',
    minDate = DEFAULT_MIN_DATE,
    maxDate = DEFAULT_MAX_DATE,
    inputClassName,
    ...props
}: DatePickerProps<CustomModifierNames, WithRange>) => {
    const {
        i18n: { language: currentLang }
    } = useTranslation()
    const datePickerLocale = locale || require(`date-fns/locale/${currentLang}/index.js`)

    const renderTextField = () => {
        return (
            customInput || (
                <TextField
                    {...textFieldProps}
                    rightIcon={textFieldProps.rightIcon || <CalendarIcon />}
                    label={label}
                    forceReadOnly
                    disableReadOnlyStyle
                    inputClassName={inputClassName ? inputClassName : 'border-none'}
                />
            )
        )
    }

    return (
        <div className='date-picker'>
            <ExternalDatePicker
                {...props}
                locale={datePickerLocale}
                customInput={renderTextField()}
                dateFormat={dateFormat}
                minDate={minDate}
                maxDate={maxDate}
            >
                {children}
            </ExternalDatePicker>
        </div>
    )
}

export default DatePicker
