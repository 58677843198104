import { GroupBase, OptionProps, components } from 'react-select'
import React from 'react'

export type SelectFieldOptionProps<
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
> = OptionProps<Option, IsMulti, Group>

const SelectFieldOption = <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>({
    children,
    data,
    ...props
}: SelectFieldOptionProps<Option, IsMulti, Group>) => {
    const optionData = data as unknown as Record<string, unknown>

    return (
        <components.Option {...props} data={data}>
            <span
                data-test-option={JSON.stringify(optionData)}
                data-test-option-value={optionData.value}
                data-test-option-label={optionData.label}
            >
                {children}
            </span>
        </components.Option>
    )
}

export default SelectFieldOption
