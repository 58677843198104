import { buildClasses } from '../../../utils/StyleHelper'
import React, { CSSProperties, PropsWithChildren, TdHTMLAttributes } from 'react'

export type PlainTableBodyRowCellPropsSticky = {
    enabled: boolean
    left: number
    zIndex?: number
}

export type PlainTableBodyRowCellProps = TdHTMLAttributes<HTMLTableDataCellElement> & {
    darken?: boolean
    sticky?: PlainTableBodyRowCellPropsSticky
    borderBottom?: boolean
}

const PlainTableBodyRowCell = ({
    children,
    style,
    className,
    borderBottom,
    sticky: { enabled, left, zIndex } = { enabled: false, left: 0, zIndex: 1 },
    ...props
}: PropsWithChildren<PlainTableBodyRowCellProps>) => {
    const stickyStyles: CSSProperties = enabled
        ? {
              position: 'sticky',
              left,
              zIndex
          }
        : {}

    const styles: CSSProperties = {
        ...style,
        ...stickyStyles
    }

    return (
        <td
            {...props}
            style={styles}
            className={buildClasses({ 'sticky-col': enabled, ' border-b border-gray-300': borderBottom }, className)}
        >
            {children}
        </td>
    )
}

export default PlainTableBodyRowCell
