import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { HTMLAttributes, PropsWithChildren } from 'react'

export type PlainTableHeaderRowProps = HTMLAttributes<HTMLTableRowElement> & {
    isRounded?: boolean
}

const PlainTableHeaderRow = ({
    children,
    isRounded,
    className,
    ...props
}: PropsWithChildren<PlainTableHeaderRowProps>) => {
    return (
        <tr
            className={buildClassesWithDefault(
                {
                    '[&>*:first-child]:rounded-l-lg': isRounded,
                    '[&>*:last-child]:rounded-r-lg': isRounded
                },
                className
            )}
            {...props}
        >
            {children}
        </tr>
    )
}

export default PlainTableHeaderRow
